import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditMoney from 'components/PropertyEditMoney/PropertyEditMoney';
import PropertyEditSwitch from 'components/PropertyEditSwitch/PropertyEditSwitch';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import salesApi from 'features/sales/sales.api';
import React, { useMemo } from 'react';
import { formatCurrency } from 'utils/helpers';
import './SalesOrderPriceEditModal.scss';

type SalesOrderPriceEdit = {
    orderId: number;
    isOverride: boolean;
    price: number | null;
};

export default function SalesOrderPriceEditModal({
    order,
    close,
}: {
    order: SalesOrderDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = salesApi.useSalesOrderApplyPriceOverrideMutation();
    const { entityTitle } = useOrderPresentation();

    const model: SalesOrderPriceEdit = useMemo(() => {
        const override_total = order.final_price[0]?.override_total;
        const price = override_total ?? order.final_price[0]?.total;
        return {
            orderId: order.id,
            isOverride: override_total !== null,
            price,
        };
    }, [order]);

    const save = async (editModel: SalesOrderPriceEdit) => {
        await saveMutation({
            orderId: editModel.orderId,
            override_total: editModel.isOverride ? editModel.price : null,
        }).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="SalesOrderPriceEditModal"
            title="Override order total"
            titleContext={order.details}
            subtitle={'Adjust the order total manually by applying an override.'}
            mobileTitle={entityTitle}
            model={model}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            editImmediately={true}
            fullHeight={false}
        >
            {({ editModel, updateField }) => (
                <>
                    <PropertyContainer>
                        <PropertyDisplay
                            label="Calculated total"
                            hint="The total amount of the order based on the products added"
                            value={formatCurrency(order.final_price[0].total)}
                        />

                        <PropertyEditSwitch
                            label="Apply an override"
                            checked={editModel.isOverride}
                            onChange={isOverride => updateField({ isOverride })}
                        />

                        {editModel.isOverride && (
                            <PropertyEditMoney
                                label="Override amount"
                                hint="A custom total to be applied to the order instead of the calculated total"
                                value={editModel.price ?? undefined}
                                disabled={!editModel.isOverride}
                                onChange={price => updateField({ price })}
                                allowBlank={true}
                                validationRequired={
                                    editModel.isOverride && 'Please enter an override amount'
                                }
                            />
                        )}
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
