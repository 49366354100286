import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
    return {};
};

const slice = createSlice({
    name: 'sales',
    initialState: getInitialState(),
    reducers: {},
    // extraReducers: builder => {},
});

// export const {} = slice.actions;

export default slice.reducer;
