import SalesOrderStatus, { SalesOrderStatusDisplay } from 'features/sales/enums/SalesOrderStatus';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './SalesOrderStatusBadge.scss';

export default function SalesOrderStatusBadge({
    model,
    size = 'large',
}: {
    model?: SalesOrder | SalesOrderDetail;
    size?: 'small' | 'large';
}) {
    // Because this this badge is used to show both parent and child orders
    // we need to check two places to know if the order has been submitted yet
    // It is submitted if is_quote === 0 (this is a child order) or if latest_revision_id is not null (this is a parent/quote)
    const status =
        model?.latest_revision_id ?? model?.is_quote === 0
            ? SalesOrderStatus.Ordered
            : SalesOrderStatus.Quote;

    return model ? (
        <div
            className={coalesceClassNames(
                'SalesOrderStatusBadge',
                `SalesOrderStatusBadge--${size}`,
                status,
            )}
        >
            {SalesOrderStatusDisplay.display(status)}
        </div>
    ) : null;
}
