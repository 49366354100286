import { Tooltip, Typography } from '@mui/material';
import Icons from 'Icons';
import { DashboardData } from 'features/dashboard/models/DashboardData';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './ScoreboardSalesWidget.scss';

export default function ScoreboardSalesWidget({ rows }: { rows: DashboardData['sales_by_rep'] }) {
    // The max total sales of all reps, used to calculate the bar length
    const maxSales = useMemo(
        () =>
            rows.reduce((accumulator, currentValue) => {
                const totalSales = currentValue.orders + currentValue.quotes;
                return totalSales > accumulator ? totalSales : accumulator;
            }, 0),
        [rows],
    );
    return (
        <div className="ScoreboardSalesWidget">
            <div className="ScoreboardSalesWidget__Title">
                <h3>Sales By Rep</h3>
                <div className="ScoreboardSalesWidget__Title__Icon">
                    <Tooltip
                        title={
                            <Typography fontSize={12}>
                                Total number of unarchived sales orders broken down by sales rep and
                                order status
                            </Typography>
                        }
                        placement="top"
                        arrow={true}
                    >
                        <span>
                            <Icons.Info />
                        </span>
                    </Tooltip>
                </div>
            </div>

            <div
                role="grid"
                className="ScoreboardSalesWidget__Table"
            >
                {rows.map(row => (
                    <Link
                        role="row"
                        key={row.name}
                        className="ScoreboardSalesWidget__Table__Row"
                        to={`/sales-orders?rep=${
                            row.name ? row.name.replace(/ /g, '+') : '__none'
                        }`}
                    >
                        <div
                            role="gridcell"
                            className="ScoreboardSalesWidget__Table__Cell ScoreboardSalesWidget__Table__Cell--name"
                        >
                            {row.name ? <span>{row.name}</span> : <i>None</i>}
                        </div>
                        <div
                            role="gridcell"
                            className="ScoreboardSalesWidget__Table__Cell ScoreboardSalesWidget__Table__Cell--value"
                        >
                            <SalesBar
                                quotes={row.quotes}
                                orders={row.orders}
                                maxSales={maxSales}
                            ></SalesBar>
                        </div>
                        <div
                            role="gridcell"
                            className="ScoreboardSalesWidget__Table__Cell ScoreboardSalesWidget__Table__Cell--icon"
                        >
                            <Icons.ChevronRight size={20} />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

function SalesBar({
    quotes,
    orders,
    maxSales,
}: {
    quotes: number;
    orders: number;
    maxSales: number;
}) {
    const totalSales = orders + quotes;
    const progressWidth = (100 * totalSales) / maxSales;
    const ordersPercent = (100 * orders) / totalSales;

    if (!totalSales) {
        return null;
    }
    return (
        <Tooltip
            arrow={true}
            placement="top"
            title={
                <Typography fontSize={12}>
                    {orders} {pluralize('order', orders)}, {quotes} {pluralize('quote', quotes)}
                </Typography>
            }
        >
            <div
                className="ScoreboardSalesWidget__SalesBar"
                style={{
                    width: `${progressWidth}%`,
                }}
            >
                <div
                    className="ScoreboardSalesWidget__SalesBar__Order"
                    style={{
                        width: `${ordersPercent}%`,
                    }}
                />
            </div>
        </Tooltip>
    );
}
