import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'features/auth/auth.api';
import settingsApi from 'features/settings/settings.api';
import posthog from 'posthog-js';
import type { RootState } from 'store/store';
import { Permission } from './models/Permission';
import { User } from './models/User';

type AuthState = {
    user: User | undefined;
    isAuthenticated: boolean | null; // if null it means we aren't sure if we are authed or not
    didLogout: boolean;
    didLoginError: boolean;
    didSessionExpire: boolean;
    permissions: Permission[];
    isUpdateAvailable: boolean;
};
const getInitialState = (): AuthState => {
    return {
        user: undefined,
        isAuthenticated: null,
        didLogout: false,
        didSessionExpire: localStorage.getItem('didSessionExpire') === 'true',
        didLoginError: false,
        permissions: [],
        isUpdateAvailable: false,
    };
};

const handleClearAuth = ({
    didSessionExpire,
    isAuthenticated,
}: {
    didSessionExpire?: boolean;
    isAuthenticated: boolean | null;
}) => {
    const state = getInitialState();
    if (didSessionExpire) {
        localStorage.setItem('didSessionExpire', 'true');
    }
    if (isAuthenticated) {
        state.didLogout = true;
    }
    state.isAuthenticated = false;
    if (window.POSTHOG_ENABLED) {
        posthog.reset();
    }

    return state;
};

const slice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        clearAuth(
            state,
            action: PayloadAction<
                | {
                      didSessionExpire?: boolean;
                  }
                | undefined
            >,
        ) {
            return handleClearAuth({
                didSessionExpire: action.payload?.didSessionExpire,
                isAuthenticated: state.isAuthenticated,
            });
        },
    },
    extraReducers: builder => {
        builder
            // Login succeeded
            .addMatcher(authApi.endpoints.login.matchFulfilled, state => {
                state.isAuthenticated = true;
                state.didSessionExpire = false;
                state.didLoginError = false;
                localStorage.removeItem('didSessionExpire');
            })
            // Login failed
            .addMatcher(authApi.endpoints.login.matchRejected, state => {
                state.isAuthenticated = false;
                state.didSessionExpire = false;
            })

            // Restore session succeeded
            .addMatcher(authApi.endpoints.restoreSession.matchFulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.permissions = action.payload.permissions;
                state.user = action.payload.user;
            })
            // Restore session failed
            .addMatcher(authApi.endpoints.restoreSession.matchRejected, state => {
                if (state.isAuthenticated) {
                    state.didLoginError = true;
                }
                state.isAuthenticated = false;
            })
            .addMatcher(authApi.endpoints.checkVersion.matchFulfilled, (state, action) => {
                state.isUpdateAvailable =
                    action.payload.GIT_COMMIT !== process.env.REACT_APP_GIT_HASH;
            })
            // load app settings failed
            .addMatcher(settingsApi.endpoints.accountSettings.matchRejected, state => {
                if (state.isAuthenticated) {
                    state.didLoginError = true;
                }
                state.isAuthenticated = false;
            });
    },
});

export const { clearAuth } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectDidLogout = (state: RootState) => state.auth.didLogout;
export const selectDidSessionExpire = (state: RootState) => state.auth.didSessionExpire;
export const selectDidLoginError = (state: RootState) => state.auth.didLoginError;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectNewVersionAvailable = (state: RootState) => state.auth.isUpdateAvailable;
