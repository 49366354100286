import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import { SalesOrderStatusDisplay } from 'features/sales/enums/SalesOrderStatus';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi, { SalesOrderListParams } from 'features/sales/sales.api';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import SalesOrderStatusBadge from '../SalesOrderStatusBadge/SalesOrderStatusBadge';
import './SalesOrdersTable.scss';

export default function SalesOrdersTable() {
    const [urlParams] = useSearchParams();

    const salesRepOptions = useSalesRepOptions({ includeNotSet: true });

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<SalesOrderListParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    urlParam: 'search',
                })
                .criteria({
                    field: 'sales_rep_override',
                    label: 'Sales rep',
                    type: 'select',
                    options: salesRepOptions,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'rep',
                })
                .criteria({
                    field: 'date',
                    label: 'Date',
                    type: 'date',
                    range: 'past',
                    blankValue: 'all',
                    defaultValue: '-30',
                    urlParam: 'date',
                })
                .criteria({
                    field: 'status',
                    label: 'Status',
                    type: 'select',
                    options: SalesOrderStatusDisplay.options,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'status',
                })
                .criteria({
                    field: 'archived',
                    label: 'Archived',
                    type: 'toggle',
                    defaultValue: 'false',
                    urlParam: 'archived',
                })
                .build(),
        [salesRepOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery(criteriaFields);

    const query = salesApi.useSalesOrderListQuery(queryParams);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<SalesOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    const breakpoints = useBreakpoints();

    const columns = useMemo(
        () =>
            ColumnBuilder<SalesOrder>()
                .column({
                    label: 'Order',
                    key: 'unique_id',
                    render: (item: SalesOrder) =>
                        breakpoints.isLargeOnly ? (
                            item.unique_id
                        ) : (
                            <OrderCell
                                key={item.unique_id}
                                item={item}
                            />
                        ),
                })
                .column({
                    label: 'Sidemark',
                    key: 'sidemark',
                    getValue: item => item.sidemark,
                    isSortable: false,
                })
                .column(
                    breakpoints.isMediumUp && {
                        label: 'Sales rep',
                        key: 'sales_rep_override',
                        getValue: item => item.sales_rep_override,
                        isSortable: false,
                    },
                )
                .column(
                    breakpoints.isLargeOnly && {
                        label: 'Date',
                        key: 'created_at',
                        render: item => formatDateTimeRelative(item.created_at),
                    },
                )
                .column(
                    breakpoints.isLargeOnly && {
                        label: 'Products',
                        key: 'products',
                        align: 'center',
                        render: item => {
                            const count = item.final_price[0]?.products_selected_with_quantity;
                            return count > 0 ? count : null;
                        },
                    },
                )
                .column({
                    label: 'Total',
                    key: 'total',
                    isSortable: false,
                    getValue: item => item.final_price[0]?.total,
                    renderValue: (val, item: SalesOrder) =>
                        breakpoints.isLargeOnly ? (
                            val && formatCurrency(val)
                        ) : (
                            <TotalCell
                                key={item.id}
                                item={item}
                            />
                        ),
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    width: '1px',
                    render: item => <StatusCell item={item} />,
                })
                .build(),
        [breakpoints.isLargeOnly, breakpoints.isMediumUp],
    );

    return (
        <div className="SalesOrdersTable">
            <div className="SalesOrdersTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                    onRefresh={breakpoints.isSmallDown ? query.refetch : undefined}
                    isRefreshing={query.isFetching}
                />
            </div>

            <DataTable
                className="SalesOrdersTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                rowLinkTo={item => `${item.id}?${urlParams}`}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                columns={columns}
                showHeader={breakpoints.isMediumUp}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}

function OrderCell({ item }: { item: SalesOrder }) {
    const breakpoints = useBreakpoints();

    return (
        <div className="SalesOrdersTable__OrderCell">
            <div className="SalesOrdersTable__OrderCell__Id">{item.unique_id}</div>
            <div className="SalesOrdersTable__OrderCell__Date">
                {formatDateRelative(item.created_at, {
                    hideDayName: breakpoints.isSmallDown,
                })}
            </div>
        </div>
    );
}

function TotalCell({ item }: { item: SalesOrder }) {
    const count = item.final_price[0]?.products_selected_with_quantity ?? 0;
    const total = item.final_price[0]?.total ?? 0;

    return (
        <div className="SalesOrdersTable__TotalCell">
            {count > 0 && (
                <>
                    <div className="count">
                        {count} <small>{count === 1 ? 'product' : 'products'}</small>
                    </div>
                    <div className="price">{total > 0 && formatCurrency(total)}</div>
                </>
            )}
        </div>
    );
}

function StatusCell({ item }: { item: SalesOrder }) {
    return (
        <div className="SalesOrdersTable__StatusCell">
            <SalesOrderStatusBadge
                model={item}
                size="small"
            />
            {item.is_archived === 1 && (
                <div className="SalesOrdersTable__StatusCell__Archived">Archived</div>
            )}
        </div>
    );
}
