import { useMemo } from 'react';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from '../models/PurchaseOrderDetail';
import purchasesApi from '../purchases.api';

export default function useGetProductManufacturerOrders({
    order,
    productId,
}: {
    order: PurchaseOrderDetail;
    productId: number;
}) {
    const isSubmitted = order.status === PurchaseOrderStatus.Submitted;
    const orderingQuery = purchasesApi.usePurchaseOrderOrderingQuery(order.id, {
        skip: !isSubmitted,
    });

    const orders = useMemo(() => {
        const data = orderingQuery.data;

        if (data) {
            return (
                // ignore any forms which dont have a supplier_order_number
                data.forms
                    .filter(f => !!f.supplier_order_number)
                    .flatMap(f => f.manufacturer_orders)
                    .filter(o => o.order_window_product_ids.includes(productId))
            );
        }
        return undefined;
    }, [orderingQuery.data, productId]);

    return orders;
}
