import Layout from 'components/Layout/Layout';
import authApi from 'features/auth/auth.api';
import { selectIsAuthenticated } from 'features/auth/auth.slice';
import settingsApi from 'features/settings/settings.api';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import KanbanPage from 'pages/KanbanPage/KanbanPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import PurchaseOrderSupplierOrderPage from 'pages/PurchaseOrderSupplierOrderPage/PurchaseOrderSupplierOrderPage';
import PurchaseDetailPage from 'pages/PurchaseOrdersPage/PurchaseDetailPage';
import PurchaseOrderCreatePage from 'pages/PurchaseOrdersPage/PurchaseOrderCreatePage';
import PurchaseOrderProductPage from 'pages/PurchaseOrdersPage/PurchaseOrderProductPage';
import PurchasesPage from 'pages/PurchaseOrdersPage/PurchaseOrdersPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import SalesOrderCreatePage from 'pages/SalesOrdersPage/SalesOrderCreatePage';
import SalesOrderDetailPage from 'pages/SalesOrdersPage/SalesOrderDetailPage';
import SalesOrderProductPage from 'pages/SalesOrdersPage/SalesOrderProductPage';
import SalesOrdersPage from 'pages/SalesOrdersPage/SalesOrdersPage';
import BundlesPage from 'pages/SettingsPage/BundlesPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import SupplierPage from 'pages/SupplierPage/SupplierPage';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function AppRoutes() {
    usePageTitle(window.HOSTED_DOMAIN_NAME || 'Quoterite');
    const isAuthed = useAppSelector(selectIsAuthenticated);

    // Restore the session by calling /me
    // There is no way to know if the current cookie is valid without calling this
    // If this succeeds then will also set isAuthed to true
    const restoreSessionQuery = authApi.useRestoreSessionQuery(undefined, {
        // run if Auth is true or null (unknown)
        // skip if we have already confirmed that isAuthed is false
        skip: isAuthed === false,
    });
    const settingsQuery = settingsApi.useAccountSettingsQuery(undefined, {
        // run if Auth is true or null (unknown)
        // skip if we have already confirmed that isAuthed is false
        skip: isAuthed === false,
    });

    /** Redirect if the user is already logged in */
    const RequiresUnAuthed = useCallback(() => {
        if (isAuthed) {
            // Important - dont use `useSearchParams` for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            const search = new URLSearchParams(window.location.search);
            const returnUrl = search.get('returnUrl');
            return <Navigate to={returnUrl || '/'} />;
        }
        return <Outlet />;
    }, [isAuthed]);

    /** Redirect if the user is not logged in */
    const RequiresAuthed = useCallback(() => {
        if (isAuthed === false) {
            // if we are def not authed, go to the login
            // Important - dont use the `useLocation` hook for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            let url = window.location.pathname;
            if (window.location.search) {
                url += `${window.location.search}`;
            }
            return <Navigate to={`/login?returnUrl=${encodeURIComponent(url)}`} />;
        }
        if (isAuthed === true && restoreSessionQuery.isSuccess && settingsQuery.isSuccess) {
            // if we are def authed then carry on
            return <Outlet />;
        }
        // Waiting for session restore
        return <Layout isLoading={true} />;

        // TODO error state?
    }, [isAuthed, restoreSessionQuery.isSuccess, settingsQuery.isSuccess]);

    useEffect(() => {
        document.querySelector('body')?.classList.add('hide-load');
    }, []);

    return (
        <>
            <Suspense fallback={<Layout isLoading={true} />}>
                <Routes>
                    <Route element={<RequiresUnAuthed />}>
                        <Route
                            path="/login"
                            element={<LoginPage />}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPasswordPage />}
                        />
                    </Route>
                    <Route element={<RequiresAuthed />}>
                        <Route element={<Layout />}>
                            <Route
                                path="/dashboard"
                                element={<DashboardPage />}
                            />
                            <Route
                                path="/sales-orders"
                                element={<SalesOrdersPage />}
                            >
                                <Route
                                    path=":orderId"
                                    element={<SalesOrderDetailPage />}
                                >
                                    <Route
                                        path="products/:productId"
                                        element={<SalesOrderProductPage />}
                                    />
                                </Route>
                                <Route
                                    path="new"
                                    element={<SalesOrderCreatePage />}
                                />
                            </Route>
                            <Route
                                path="/purchase-orders"
                                element={<PurchasesPage />}
                            >
                                <Route
                                    path=":orderId"
                                    element={<PurchaseDetailPage />}
                                >
                                    <Route
                                        path="products/:productId"
                                        element={<PurchaseOrderProductPage />}
                                    />
                                    <Route
                                        path="supplier-order/:manufacturerOrderId"
                                        element={<PurchaseOrderSupplierOrderPage />}
                                    />
                                </Route>
                                <Route
                                    path="new"
                                    element={<PurchaseOrderCreatePage />}
                                />
                            </Route>
                            <Route
                                path="/documents"
                                element={<DocumentsPage />}
                            />
                            <Route
                                path="/settings"
                                element={<SettingsPage />}
                            />
                            <Route
                                path="/supplier"
                                element={<SupplierPage />}
                            />
                            <Route
                                path="/bundles"
                                element={<BundlesPage />}
                            />

                            {/* Dev only route for testing */}
                            <Route
                                path="/kanban"
                                element={<KanbanPage />}
                            />

                            <Route
                                path="*"
                                element={<Navigate to={'/dashboard'} />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}
