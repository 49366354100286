import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum SalesOrderStatus {
    Quote = 'QUOTE',
    Ordered = 'ORDERED',
}

export default SalesOrderStatus;

const labels: EnumDisplayLabels<typeof SalesOrderStatus> = {
    Quote: 'Quote',
    Ordered: 'Ordered',
};

export const SalesOrderStatusDisplay = createDisplayEnum(SalesOrderStatus, labels);
