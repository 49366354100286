import Kanban, { KanbanColumnDefinition } from 'components/Kanban/Kanban';
import PageHeader from 'components/PageHeader/PageHeader';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function KanbanPage() {
    usePageTitle('Kanban Test Page');

    const query = salesApi.useSalesOrderListQuery({
        criteria: {
            archived: '',
            date: '',
            status: '',
            search: '',
            sales_rep_override: '',
        },
        paging: {
            skip: 0,
            limit: 50,
        },
    });

    const columns: KanbanColumnDefinition<SalesOrder>[] = [
        {
            id: 'UNSCHEDULED',
            title: 'Unscheduled',
            group: 'NEW',
            cardSelector: item => item.is_quote === 1,
        },
        {
            id: 'SCHEDULED',
            title: 'Scheduled',
            group: 'NEW',
            cardSelector: item => item.is_quote === 0,
        },
        {
            id: 'IN_PROGRESS',
            title: 'Quote',
            group: 'QUOTING',
            cardSelector: () => false,
        },
        {
            id: 'QUOTING',
            title: 'Quote sent',
            group: 'QUOTING',
            cardSelector: () => false,
        },
        {
            id: 'done',
            title: 'Done',
            group: 'COMPLETED',
            cardSelector: () => false,
        },
    ];

    return (
        <>
            <PageHeader title="Kanban Test Page" />
            <Kanban
                data={query.data?.data}
                columns={columns}
                renderCard={item => <CardComp item={item} />}
            />
            <Outlet />
        </>
    );
}

function CardComp({ item }: { item: SalesOrder }) {
    return (
        <div data-id={item.id}>
            <h3>{item.unique_id}</h3>
            <p>{item.sidemark}</p>
        </div>
    );
}
