import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';
import { Product } from './Product';

export const OrderWindowProductSchema = z.object({
    id: z.number(),
    created_at: dateTimeOldSystem(),
    order_id: z.number(),
    order_window_id: z.number(),
    product_id: z.number(),
    master_products_name: z.string().nullable(),
    products_name: z.string(),
    products_type: z.string(), // enum? standard | custom
    brand_id: z.number(),
    brands_name: z.string(),
    category_id: z.number(),
    categories_name: z.string(),
    details: z.string().nullable(),
    notes: z.string().nullable(),
    quantity: z.number(),
    width: z.number(),
    height: z.number(),
    is_custom: z.number(), // 0 or 1
    is_enabled: z.number(), // 0 or 1
    is_selected: z.number(), // 0 or 1
    is_taxable: z.number(), // 0 or 1
    has_freight: z.number(), // 0 or 1
    has_installation: z.number(), // 0 or 1
    product_eta_days: z.number().nullable().default(null),
    product_option_selects: z.array(
        z.object({
            name: z.string(),
        }),
    ),
    final_price: z.object({
        cost_price: z.string(),
        discounted_retail_price: z.string(),
    }),
    extension: z.object({
        is_oversized: z.number(), // 0 or 1 (oversize) or 2 (undersize)
    }),
    validation: z
        .object({
            sixteenth_restriction: z.number(), // 0 or 1,
            no_selections_made: z.number(), // 0 or 1,
            unanswered_mandatory_fields: z.string(), // '',
            oversized_fields: z.string().optional(), // '["Hinge Door"]',
            order_id: z.number(),
            order_window_id: z.number(),
            order_window_product_id: z.number(),
            message_block: z.string().nullable(),
            is_valid: z.boolean(),
        })
        .optional(),
});

export type OrderWindowProduct = z.infer<typeof OrderWindowProductSchema>;

export class OrderWindowProductFactory {
    static create({ orderId, product }: { orderId: number; product: Product }) {
        const model: Partial<OrderWindowProduct> = {
            order_id: orderId,
            product_id: product.id,
            products_name: product.name,
            products_type: '',
            brand_id: product.brand_id,
            brands_name: product.brand_name,
            category_id: product.category_id,
            categories_name: product.category_name,
            details: '',
            quantity: 1,
            final_price: {
                cost_price: '',
                discounted_retail_price: '',
            },
            is_custom: 0,
            is_enabled: 1,
            product_option_selects: [],
        };

        return model;
    }
}
