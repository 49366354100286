import z from 'zod';

export const ProductOptionSchema = z.object({
    id: z.number(),
    name: z.string(),
    notes: z.string().nullable(),
    type: z.string(),
    select_label: z.string().nullable(),
});

export function getOptionDisplayValue(option: ProductOption) {
    switch (option.type) {
        case 'text':
        case 'text_multiline':
            return option.notes;
        default:
            return option.select_label;
    }
}

export type ProductOption = z.infer<typeof ProductOptionSchema>;
