import { dateFilterOptionsAny } from 'components/DataTableFilters/Filters/DateFilter';
import { SALES_REP_NOT_SET } from 'features/orders/orders.api';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { ApiTagType, api } from 'services/api';
import { parseEnum } from 'utils/helpers';
import z from 'zod';
import {
    ManufacturerOrderHistoryResult,
    ManufacturerOrderHistoryResultSchema,
} from './models/ManufacturerOrderHistoryResult';
import {
    ManufacturerOrderStatus,
    ManufacturerOrderStatusSchema,
} from './models/ManufacturerOrderStatus';
import { PurchaseOrderDetail, PurchaseOrderDetailSchema } from './models/PurchaseOrderDetail';
import { PurchaseOrderEditable } from './models/PurchaseOrderEditable';
import {
    PurchaseOrderListResult,
    PurchaseOrderListResultSchema,
} from './models/PurchaseOrderListResult';
import {
    PurchaseOrderOrderingInfo,
    PurchaseOrderOrderingInfoSchema,
} from './models/PurchaseOrderOrderingInfo';

export type PurchaseOrderListParams = DynamicQueryParams<{
    search?: string;
    date: string;
    status: string;
    archived: string;
    sales_rep_override?: string;
}>;

enum PurchaseOrderStatus {
    Draft = 'DRAFT',
    Submitted = 'SUBMITTED',
}

function buildPurchaseOrderListFilters(params: PurchaseOrderListParams): {
    isArchived: boolean;
    search: string;
    salesRep?: string;
    dateFrom: string;
    dateTo: string;
    status?: 'DRAFT' | 'SUBMITTED';
} {
    // filter by date - api requires this field so we default to last 30 days (-30)
    // but user can also select 'all' to remove the filter
    const dateVal = params.criteria.date || '-30';
    const dateOpt =
        dateVal === 'all' ? undefined : dateFilterOptionsAny.find(o => o.value === dateVal);

    const dateFrom = dateOpt?.minDate.toISO({ includeOffset: false }) ?? '2000-01-01T00:00:00.000';
    const dateTo = dateOpt?.maxDate.toISO({ includeOffset: false }) ?? '2050-01-01T00:00:00.000';

    // to allow filtering by sales_rep_override === '' (not set)
    // we use a magic string SALES_REP_NOT_SET, which translates to undefined for the API call
    let salesRep = params.criteria.sales_rep_override;
    if (salesRep === SALES_REP_NOT_SET) {
        salesRep = '';
    } else if (salesRep === '') {
        salesRep = undefined;
    }

    return {
        search: params.criteria.search ?? '',
        dateFrom,
        dateTo,
        isArchived: params.criteria.archived === 'true',
        salesRep,
        status: parseEnum(PurchaseOrderStatus, params.criteria.status),
    };
}

const purchasesApi = api.injectEndpoints({
    endpoints: build => ({
        purchaseOrderList: build.query<PurchaseOrderListResult, PurchaseOrderListParams>({
            query: params => ({
                url: '/purchaseorder',
                method: 'POST',
                data: {
                    filters: buildPurchaseOrderListFilters(params),
                    pagination: {
                        limit: params.paging.limit,
                        offset: params.paging.skip,
                        // ordering: params.sort ? [params.sort] : [],
                    },
                },
            }),
            transformResponse: (result: unknown) => PurchaseOrderListResultSchema.parse(result),
            providesTags: [ApiTagType.PurchaseOrderList],
        }),

        purchaseOrderDetail: build.query<PurchaseOrderDetail, number>({
            query: id => ({
                url: `/purchaseorder/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                return PurchaseOrderDetailSchema.parse(result);
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.PurchaseOrderDetail, id }],
        }),

        /** Create a new Draft Purchase Order */
        purchaseOrderCreate: build.mutation<number, PurchaseOrderEditable>({
            query: model => ({
                url: `/old/quotes`,
                method: 'POST',
                data: {
                    company_id: model.company_id,
                    employee_id: model.employee_id,
                    sidemark: model.sidemark,
                    sales_rep_override: model.sales_rep_override,
                    details: model.details,
                    is_draft: 1,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.object({
                        id: z.number(),
                    }),
                });
                return schema.parse(result).data.id;
            },
            invalidatesTags: [
                ApiTagType.PurchaseOrderList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
            ],
        }),

        /** Update an order - Quotes/Drafts only */
        purchaseOrderUpdate: build.mutation<void, PurchaseOrderEditable>({
            query: model => ({
                url: `/old/quotes/${model.id}/update-details`,
                method: 'POST',
                data: {
                    company_id: model.company_id,
                    sidemark: model.sidemark,
                    sales_rep_override: model.sales_rep_override,
                    details: model.details,
                    employee_id: model.employee_id,
                },
            }),
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    purchasesApi.util.updateQueryData('purchaseOrderDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                // invalidate list view
                dispatch(
                    purchasesApi.util.invalidateTags([
                        ApiTagType.PurchaseOrderList,
                        ApiTagType.SalesRepList,
                        { type: ApiTagType.PurchaseOrderDetail, id: model.id },
                    ]),
                );
            },
        }),

        /** Clone a purchase order and create a new draft */
        purchaseOrderClone: build.mutation<
            number,
            {
                orderId: number;
                sidemark: string;
                sales_rep_override: string;
                details: string;
                windowIds: number[];
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/clone`,
                method: 'POST',
                data: {
                    type: 'whole_quote',
                    with_contact: 1,
                    details: args.details,
                    sidemark: args.sidemark,
                    sales_rep_override: args.sales_rep_override,
                    is_draft: true,
                    order_window_ids: args.windowIds,
                    first_run: true,
                    last_run: true,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    cloned_order: z.object({
                        id: z.number(),
                    }),
                });
                return schema.parse(result).cloned_order.id;
            },
            invalidatesTags: [
                ApiTagType.PurchaseOrderList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
            ],
        }),

        purchaseOrderArchive: build.mutation<void, number>({
            query: id => ({
                url: `/old/orders/archive/1`,
                method: 'POST',
                data: {
                    ids: [id],
                },
            }),
            invalidatesTags: (res, err, id) => [
                ApiTagType.PurchaseOrderList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
                { type: ApiTagType.PurchaseOrderDetail, id },
            ],
        }),

        purchaseOrderUnarchive: build.mutation<void, number>({
            query: id => ({
                url: `/old/orders/archive/0`,
                method: 'POST',
                data: {
                    ids: [id],
                },
            }),
            invalidatesTags: (res, err, id) => [
                ApiTagType.PurchaseOrderList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
                { type: ApiTagType.PurchaseOrderDetail, id },
            ],
        }),

        /** Submit purchase order to manufacturer */
        purchaseOrderSubmit: build.mutation<
            void,
            {
                orderId: number;
                requiredDate: string;
                shippingAddress: string;
                shippingMethodId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/convert`,
                method: 'POST',
                data: {
                    type: 'order',
                    process_type: '',
                    orderOnline: true,
                    required_date: args.requiredDate,
                    shipping_address: args.shippingAddress,
                    shipping_method_id: args.shippingMethodId,
                },
            }),
            invalidatesTags: (res, err, args) => [
                ApiTagType.PurchaseOrderList,
                ApiTagType.DashboardData,
                { type: ApiTagType.PurchaseOrderDetail, id: args.orderId },
            ],
        }),

        /** Get ordering info (manufacturer/purchase order status) for an order  */
        purchaseOrderOrdering: build.query<PurchaseOrderOrderingInfo, number>({
            query: orderId => ({
                url: `/old/orders/ordering?order_id=${orderId}&brand_id=`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: PurchaseOrderOrderingInfoSchema,
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.PurchaseOrderOrderingInfo, id }],
        }),

        /** Get history records for an order */
        manufacturerOrderHistory: build.query<
            ManufacturerOrderHistoryResult,
            DynamicQueryParams<{
                manufacturerOrderId: number;
            }>
        >({
            query: params => ({
                url:
                    `/old/trackers?` +
                    `arguments%5Btype%5D%5B%5D=order-update` +
                    `&arguments%5Btype%5D%5B%5D=order-line-split` +
                    `&arguments%5Btype%5D%5B%5D=order-dl` +
                    `&arguments%5Bmodel%5D=ManufacturerOrder` +
                    `&arguments%5Bid%5D=${params.criteria.manufacturerOrderId}` +
                    `&arguments%5Bevent_item%5D=` +
                    `&arguments%5Bfilters%5D%5Bsearch_text%5D=` +
                    `&pagination%5Boffset%5D=${params.paging.skip}` +
                    `&pagination%5Blimit%5D=${params.paging.limit}`,
                method: 'GET',
            }),
            transformResponse: result => ManufacturerOrderHistoryResultSchema.parse(result),
            providesTags: (res, err, params) => [
                {
                    type: ApiTagType.ManufacturerOrderHistory,
                    id: params.criteria.manufacturerOrderId,
                },
            ],
        }),
        /** Load sales order status values */
        manufacturerOrderStatuses: build.query<ManufacturerOrderStatus[], number>({
            query: id => ({
                url: `/manufacturer/${id}/order-statuses`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                return z.array(ManufacturerOrderStatusSchema).parse(result);
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.ManufacturerOrderStatuses, id }],
        }),
    }),
});

export default purchasesApi;
