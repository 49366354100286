import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';

export const SalesOrderSchema = z
    .object({
        id: z.number(),
        unique_id: z.string(),
        sidemark: z.string().nullable(),
        created_at: dateTimeOldSystem(),
        latest_revision_id: z.number().nullable(),
        is_archived: z.number(),
        is_quote: z.number(),
        is_draft: z.number(),
        sales_rep_override: z.string().nullable(),

        /** Customer details */
        customer_details_override: z.string().nullable(),

        final_price: z.array(
            z.object({
                cost: z.string().transform(val => (val ? parseFloat(val) : 0)),
                sub_total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                tax: z.string().transform(val => (val ? parseFloat(val) : 0)),
                total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                products_selected: z.string().transform(val => (val ? parseInt(val, 10) : 0)),
                products_selected_with_quantity: z
                    .string()
                    .transform(val => (val ? parseInt(val, 10) : 0)),
            }),
        ),
        children: z
            .array(
                z.object({
                    id: z.number(),
                    unique_id: z.string(),
                }),
            )
            .nullable(),
    })
    .transform(model => {
        const latestChild = model.children?.find(c => c.id === model.latest_revision_id);

        // handle bad data where is_quote and is_draft are both true
        // make sure only one is true at once
        // otherwise we can crash the app wit infinite redirects
        const is_quote = model.is_draft ? 0 : model.is_quote;

        // Replace unique_id Q- prefix with O- for draft orders
        const unique_id = model.is_draft ? model.unique_id.replace(/^Q-/, 'O-') : model.unique_id;

        return {
            ...model,
            latest_revision_id: latestChild?.id,
            latest_revision_unique_id: latestChild?.unique_id,
            is_quote,
            unique_id,
            isSale: true as const,
            isPurchase: false as const,
        };
    });

export type SalesOrder = z.infer<typeof SalesOrderSchema>;
