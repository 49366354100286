import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import SalesOrderDetailModal from 'features/sales/components/SalesOrderDetailModal/SalesOrderDetailModal';
import salesApi from 'features/sales/sales.api';
import React, { useCallback, useEffect } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function SalesOrderDetailPage() {
    const { orderId } = useParams() as { orderId: string };
    return (
        // Use a separate comp so we can key it based on the orderId
        // this prevents cross-contamination of data when switching between quotes
        <SalesOrderDetailPagePure
            key={orderId}
            orderId={parseInt(orderId, 10)}
        />
    );
}

function SalesOrderDetailPagePure({ orderId }: { orderId: number }) {
    const { routeBase } = useOrderPresentation();
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const orderQuery = salesApi.useSalesOrderDetailQuery(orderId);
    usePageTitle(orderQuery.data?.unique_id);
    const windowsQuery = ordersApi.useOrderWindowListQuery(orderId);

    const handleClose = useCallback(() => {
        // Clear tab when closing modal
        urlParams.delete('tab');
        navigate(`${routeBase}?${urlParams}`);
    }, [navigate, routeBase, urlParams]);

    // Handle redirecting based on order id and state
    useEffect(() => {
        const model = orderQuery.currentData;

        // viewing draft on sales screen, redirect to purchase order
        if (model?.is_draft === 1 || model?.parent_is_draft === 1) {
            navigate(`/purchase-orders/${model.id}`);
        }

        // viewing order on sales screen
        // redirect to original quote record (dont allow viewing children)
        else if (model?.parent_is_quote === 1) {
            navigate(`/sales-orders/${model.parent_id}?${urlParams}`);
        }
    }, [
        navigate,
        orderQuery.currentData,
        orderQuery.currentData?.latest_revision_id,
        routeBase,
        urlParams,
    ]);

    return orderId ? (
        <>
            <SalesOrderDetailModal
                model={orderQuery.data}
                windows={windowsQuery.data}
                isLoading={orderQuery.isLoading || windowsQuery.isLoading}
                isError={orderQuery.isError || windowsQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </>
    ) : null;
}
