import { Tooltip } from '@mui/material';
import { ManufacturerOrder } from 'features/purchases/models/ManufacturerOrder';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateRelative } from 'utils/dateHelpers';
import { StrictUnion } from 'utils/typeHelpers';
import { ManufacturerOrderCoreStatusColorBlock } from '../ManufacturerOrderCoreStatusColorBlock/ManufacturerOrderCoreStatusColorBlock';
import './ManufacturerOrderStatusBadge.scss';

/** Props can either be a ManufacturerOrder or customerStatus and coreStatus by themselved
 * If you pass in a manufactuerOrder then you can optionally also show a tooltip with extra info
 */
type ManufacturerOrderStatusBadgeProps = StrictUnion<
    | {
          manufacturerOrder: ManufacturerOrder;
          showTooltip?: 'none' | 'statusOnly' | 'full';
          size?: 'small' | 'large';
      }
    | {
          customerStatus: string | null;
          coreStatus: string | null;
          size?: 'small' | 'large';
      }
>;

export function ManufacturerOrderStatusBadge({
    manufacturerOrder,
    showTooltip = 'none',
    customerStatus,
    coreStatus,
    size = 'small',
}: ManufacturerOrderStatusBadgeProps) {
    const badgeContent = (
        <div
            className={coalesceClassNames(
                'ManufacturerOrderStatusBadge',
                manufacturerOrder?.core_status ?? coreStatus,
                size,
            )}
        >
            {manufacturerOrder?.customer_status ?? customerStatus}
        </div>
    );

    if (!manufacturerOrder || showTooltip === 'none') {
        // just render badge without tooltip
        return badgeContent;
    }

    return (
        <Tooltip
            title={
                <div className="ManufacturerOrderStatusBadge__Tooltip">
                    {showTooltip === 'full' && (
                        <>
                            <div>Supplier order #{manufacturerOrder.manufacturer_reference}</div>
                            {manufacturerOrder.eta && (
                                <div className="ManufacturerOrderStatusBadge__Tooltip__ETA">
                                    ETA: {formatDateRelative(manufacturerOrder.eta)}
                                </div>
                            )}
                        </>
                    )}
                    <ManufacturerOrderCoreStatusColorBlock
                        coreStatus={manufacturerOrder.core_status}
                    />
                </div>
            }
            arrow
            placement="top"
            disableInteractive={true}
        >
            {badgeContent}
        </Tooltip>
    );
}
