import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import React, { useCallback, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './OrderUpdatesMessagePanel.scss';

// This legacy component is a hold-over from when the Sales Order and Purchase Order models were similar enough to share a component.
// It is anticipated that once the Sales Order models are updated that the updated version of this component can once again be used
// to display both models, allowing this legacy component to be removed.

export default function OrderUpdatesMessagePanelLegacy({
    className,
    model,
}: {
    className?: string;
    model?: SalesOrderDetail;
}) {
    const windowsQuery = ordersApi.useOrderWindowListQuery(model?.id ?? 0, { skip: !model });
    const [recalcMutation, recalcMutationStatus] = ordersApi.useOrderWindowsRecalculateMutation();

    const applyUpdates = useCallback(async () => {
        const windowIds = windowsQuery.data?.map(w => w.id);
        if (model && windowIds) {
            await recalcMutation({
                orderId: model.id,
                windowIds,
            });
        }
    }, [model, recalcMutation, windowsQuery.data]);

    const [showDetails, setShowDetails] = useState(false);

    const isApplyingUpdates = recalcMutationStatus.isLoading || recalcMutationStatus.isSuccess;

    return model ? (
        <>
            <MessagePanel
                className={coalesceClassNames('OrderUpdatesMessagePanel', className)}
                messageType="warning"
                title="Updates available"
            >
                <p>
                    This order has pending updates from the supplier that must be applied before it
                    can be submitted or edited further.
                </p>
                <p className="OrderUpdatesMessagePanel__Actions">
                    <a
                        className="Link"
                        onClick={() => setShowDetails(true)}
                    >
                        show details
                    </a>
                    <MyButton
                        label="Update now"
                        buttonType="Accent"
                        isLoading={isApplyingUpdates}
                        onClick={applyUpdates}
                    />
                </p>
            </MessagePanel>

            {showDetails && (
                <DetailsModal
                    model={model}
                    applyUpdates={applyUpdates}
                    isApplyingUpdates={isApplyingUpdates}
                    close={() => setShowDetails(false)}
                />
            )}
        </>
    ) : null;
}

function DetailsModal({
    model,
    applyUpdates,
    isApplyingUpdates,
    close,
}: {
    model: SalesOrderDetail;
    applyUpdates: () => void;
    isApplyingUpdates: boolean;
    close?: () => void;
}) {
    const { entityTitle } = useOrderPresentation();

    const productUpdates =
        model.order_window_products_that_has_updates.reduce((arr, p) => {
            const existing = arr.find(a => p.product.id === a.product.id);
            if (!existing) {
                arr.push(p);
            }
            return arr;
        }, [] as typeof model.order_window_products_that_has_updates) ?? [];
    return (
        <MyModal
            className="OrderUpdatesMessagePanel__DetailsModal"
            mobileTitle={entityTitle}
            close={close}
            header={
                <PageHeader
                    title="Updates Available"
                    subtitle="The following updates are available for this order"
                />
            }
        >
            <ul className="OrderUpdatesMessagePanel__DetailsModal__List">
                {model.has_surcharges_updates === 1 && <li>Surcharges updated</li>}
                {model.freight_installation.has_freight_updates === 1 && (
                    <li>Freight charges updated</li>
                )}
                {model.freight_installation.has_installation_updates === 1 && (
                    <li>Installation charges updated</li>
                )}

                {productUpdates.map(p => (
                    <li
                        key={p.id}
                        className="OrderUpdatesMessagePanel__DetailsModal__ProductRow"
                    >
                        <div className="name">{p.product.name}</div>
                        <ul className="updates">
                            {p.has_updates === 1 && <li>Pricing updated</li>}
                            {p.has_cost_padding_updates === 1 && <li>Cost padding updated</li>}
                            {p.has_fabric_updates === 1 && <li>Fabric updated</li>}
                            {p.has_freight_updates === 1 && <li>Freight charges updated</li>}
                            {p.has_installation_updates === 1 && (
                                <li>Installation charges updated</li>
                            )}
                            {p.has_rule_updates === 1 && <li>Rules updated</li>}
                            {p.has_surcharges_updates === 1 && <li>Surcharges updated</li>}
                        </ul>
                    </li>
                ))}
            </ul>
            <div className="OrderUpdatesMessagePanel__DetailsModal__Footer">
                <MyButton
                    className="OrderUpdatesMessagePanel__DetailsModal__Footer__ApplyButton"
                    label="Update now"
                    buttonType="Accent"
                    onClick={applyUpdates}
                    isLoading={isApplyingUpdates}
                />
            </div>
        </MyModal>
    );
}
