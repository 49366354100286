import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { delay, http, HttpResponse } from 'msw';
import { ManufacturerOrderStatus } from '../purchases/models/ManufacturerOrderStatus';

const BASE_URL = `${Env.API_BASE_URL}`;

export const ordersHandlers = [
    // Get work items by sales order id
    http.get(`${BASE_URL}/quotes/sales-reps`, async () => {
        const data = faker.helpers.multiple(faker.person.fullName, { count: 10 });
        await delay(500);
        return HttpResponse.json(data);
    }),

    http.get(`${BASE_URL}/manufacturer/:id/order-statuses`, async ({ params }) => {
        const id = params.id as string;
        const result: ManufacturerOrderStatus[] = [
            {
                id: 1,
                manufacturerId: parseInt(id, 10),
                customerStatus: 'Status',
                backgroundColor: '#ffffff',
                textColor: '#000',
                isActive: true,
                sortOrder: 1,
                coreStatus: 'UNSTARTED',
            },
            {
                id: 2,
                manufacturerId: parseInt(id, 10),
                customerStatus: 'Ready',
                backgroundColor: '#00ff00',
                textColor: '#000',
                isActive: true,
                sortOrder: 1,
                coreStatus: 'UNSTARTED',
            },
            {
                id: 3,
                manufacturerId: parseInt(id, 10),
                customerStatus: 'In Production',
                backgroundColor: '#00ff00',
                textColor: '#000',
                isActive: true,
                sortOrder: 1,
                coreStatus: 'IN_PROGRESS',
            },
        ];
        await delay(500);
        return HttpResponse.json(result);
    }),
];
