import z from 'zod';
import { ManufacturerCustomerSettingsSchema } from './ManufacturerCustomerSettings';
import { ManufacturerShippingMethodSchema } from './ManufacturerShippingMethod';

export const ManufacturerSchema = z.object({
    id: z.number(),
    name: z.string(),
    logo: z.string(),
    email: z.string(),
    timezone: z
        .string()
        .nullish()
        .transform(s => s ?? 'UTC'),
    customer_settings: ManufacturerCustomerSettingsSchema,
    shipping_methods: z.array(ManufacturerShippingMethodSchema),
});

export type Manufacturer = z.infer<typeof ManufacturerSchema>;
