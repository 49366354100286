import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useMemo } from 'react';
import SupplierOrdersTable from '../SupplierOrdersTable/SupplierOrdersTable';

export default function PurchaseOrderStatusTab({ model }: { model: PurchaseOrderDetail }) {
    const orderingQuery = purchasesApi.usePurchaseOrderOrderingQuery(model.id);

    const orders = useMemo(() => {
        return orderingQuery.data?.forms
            .filter(f => !!f.supplier_order_number)
            .flatMap(f => f.manufacturer_orders);
    }, [orderingQuery.data]);

    return (
        <div className="PurchaseOrderStatusTab">
            <SupplierOrdersTable
                purchaseOrderId={model.id}
                orders={orders}
                isError={orderingQuery.isError}
                isLoading={orderingQuery.isLoading}
            />
        </div>
    );
}
