import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyTabs.scss';

export type MyTabsTab = {
    name: string;
    label: React.ReactNode;
    content?: React.ReactNode;
};

export default function MyTabs({
    className,
    tabs,
    activeTab = '',
    setActiveTab,
    disabled = false,
}: {
    className?: string;
    tabs: (false | MyTabsTab)[];
    activeTab?: string;
    setActiveTab?: (tab: string) => void;
    disabled?: boolean;
}) {
    const goToTab = (tab: string) => {
        setActiveTab?.(tab);
    };

    const currentTab = tabs.find(t => t && t.name === activeTab);

    return (
        <div className={coalesceClassNames('MyTabs', className)}>
            <div className="MyTabs__Tabs">
                {tabs.map(
                    tab =>
                        tab && (
                            <button
                                key={tab.name}
                                className={coalesceClassNames(
                                    'MyTabs__Tabs__TabButton',
                                    `MyTabs__Tabs__TabButton--${tab.name}`,
                                    activeTab === tab.name ? 'active' : 'inactive',
                                )}
                                onClick={() => goToTab(tab.name)}
                                disabled={disabled}
                            >
                                {tab.label}
                            </button>
                        ),
                )}
            </div>

            <div className="MyTabs__TabPanels">
                {currentTab && (
                    <div
                        className={coalesceClassNames(
                            'MyTabs__TabPanel',
                            `MyTabs__TabPanel--${currentTab.name}`,
                        )}
                    >
                        {currentTab.content}
                    </div>
                )}
            </div>
        </div>
    );
}
