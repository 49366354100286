import MySelectInput from 'components/MySelectInput/MySelectInput';
import { DateTime } from 'luxon';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type DateFilterConfig = DataTableFilterBase & {
    type: 'date';
    field: string;
    range?: 'past' | 'future' | 'any';
};

export const dateFilterOptionsAny = [
    {
        label: 'Last 30 days',
        value: '-30',
        minDate: DateTime.now().minus({ days: 30 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Last 7 days',
        value: '-7',
        minDate: DateTime.now().minus({ days: 7 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Today',
        value: 'today',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Next 7 days',
        value: '7',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 7 }).endOf('day'),
    },
    {
        label: 'Next 30 days',
        value: '30',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 30 }).endOf('day'),
    },
    {
        label: 'This month',
        value: 'this-month',
        minDate: DateTime.now().startOf('month'),
        maxDate: DateTime.now().endOf('month'),
    },
    {
        label: 'This year',
        value: 'this-year',
        minDate: DateTime.now().startOf('year'),
        maxDate: DateTime.now().endOf('year'),
    },
    // TODO Custom date range
    // {
    //     label: 'Custom',
    //     value: 'Custom',
    // },
];

export const dateFilterOptionsPast = dateFilterOptionsAny.filter(o =>
    ['-30', '-7', 'today', 'this-month', 'this-year'].includes(o.value),
);

export const dateFilterOptionsFuture = dateFilterOptionsAny.filter(o =>
    ['today', '30', '7', 'this-month', 'this-year'].includes(o.value),
);

function DateFilter({ filter, onChange }: FilterCompProps<DateFilterConfig>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--DateFilter',
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={
                    filter.config.range === 'future'
                        ? dateFilterOptionsFuture
                        : filter.config.range === 'past'
                        ? dateFilterOptionsPast
                        : dateFilterOptionsAny
                }
                handleInput={handleInput}
                allowBlank={true}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<DateFilterConfig> = {
    Component: DateFilter,
    applyFilter: (filter, value, item) => {
        const minDate = dateFilterOptionsAny.find(d => d.value === value)?.minDate;
        const maxDate = dateFilterOptionsAny.find(d => d.value === value)?.maxDate;

        if (minDate && maxDate) {
            const val = getDeepValue(item, filter.field);
            const date = DateTime.fromISO(val).valueOf();
            return (
                date >= (minDate as DateTime).valueOf() && date <= (maxDate as DateTime).valueOf()
            );
        }
        return true;
    },
};
export default plugin;
