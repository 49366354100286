import { faker } from '@faker-js/faker';
import { delay } from '@redux-saga/core/effects';
import Env from 'config/Env';
import { http, HttpResponse } from 'msw';
import { fakePurchaseOrderDetails } from './faker/PurchaseOrderFaker';
import { PurchaseOrder } from './models/PurchaseOrder';

const BASE_URL = `${Env.API_BASE_URL}`;

export const purchasesHandlers = [
    http.post(`${BASE_URL}/purchaseorder`, async ({ request }) => {
        const body = (await request.json()) as {
            filters: {
                search: string;
                start: string;
                end: string;
                is_archived: boolean;
                sales_rep_override: string;
            };
            pagination: {
                limit: number;
                offset: number;
            };
        };
        // map detail model to list model
        const data = fakePurchaseOrderDetails
            .slice(body.pagination.offset, body.pagination.limit)
            .map(
                (d): PurchaseOrder => ({
                    isPurchase: d.isPurchase,
                    isSale: d.isSale,
                    status: d.status,
                    id: d.id,
                    tuid: d.tuid,
                    createdAt: d.createdAt,
                    isArchived: d.isArchived,
                    salesRep: d.salesRep,
                    sidemark: d.sidemark ?? '',
                    customerDetails: d.customerDetails ?? '',
                    totalCost: d.totalCost,
                    totalQuantity: d.totalQuantity,
                    context: {
                        coreStatus: {
                            UNSTARTED: faker.number.int({ min: 0, max: 100 }),
                            IN_PROGRESS: faker.number.int({ min: 0, max: 100 }),
                            ON_HOLD: faker.number.int({ min: 0, max: 100 }),
                            COMPLETED: faker.number.int({ min: 0, max: 100 }),
                            CANCELLED: faker.number.int({ min: 0, max: 100 }),
                        },
                    },
                }),
            );

        const result = {
            data,
            total: fakePurchaseOrderDetails.length,
        };
        await delay(500);
        return HttpResponse.json(result);
    }),

    http.get(`${BASE_URL}/purchaseorder/:id`, async ({ params }) => {
        const id = params.id as string;
        const result = fakePurchaseOrderDetails.find(p => `${p.id}` === id);
        await delay(500);
        return HttpResponse.json(result);
    }),
];
