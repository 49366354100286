import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import DocumentDetailModal from 'features/documents/components/DocumentDetailModal/DocumentDetailModal';
import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import DocumentsTable from 'features/documents/components/DocumentsTable/DocumentsTable';
import documentsApi from 'features/documents/documents.api';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import React, { useCallback, useMemo, useState } from 'react';
import './OrderDocuments.scss';

// This comp functions for documents attached to both SalesOrders and PurchaseOrders
export default function OrderDocuments({
    orderId,
    isReadOnly,
}: {
    orderId: number;
    isReadOnly: boolean;
}) {
    const { entityTitle } = useOrderPresentation();

    const query = ordersApi.useOrderDocumentsQuery(orderId);
    const [attachMutation] = ordersApi.useOrderDocumentAttachMutation();
    const [archiveMutation] = ordersApi.useOrderDocumentArchiveMutation();

    const categoriesQuery = documentsApi.useProductDocumentCategoryListQuery();
    const firstCategoryId = useMemo(
        () => categoriesQuery.data?.[0]?.id ?? 0,
        [categoriesQuery.data],
    );

    const [detailDocId, setDetailDocId] = useState<number>();
    const detailModel = useMemo(
        () => detailDocId && query.data?.data.find(d => d.id === detailDocId),
        [detailDocId, query.data?.data],
    );

    /** After the document has been uploaded, attach it to an order */
    const attachUploadedDocument = useCallback(
        (document: DocumentUploadResultDocument) => {
            return attachMutation({
                document,
                orderId,
                categoryId: firstCategoryId,
            }).unwrap();
        },
        [attachMutation, firstCategoryId, orderId],
    );

    const handleArchive = useCallback(
        async (documentId: number) => {
            return archiveMutation({
                orderId,
                documentId,
            }).unwrap();
        },
        [archiveMutation, orderId],
    );

    const [showUpload, setShowUpload] = useState(false);

    return (
        <div className="OrderDocuments">
            {!isReadOnly && (
                <div className="OrderDocuments__Header">
                    <h3 className="OrderDocuments__Header__Title">Documents</h3>
                    <MyButton
                        className="OrderDocuments__Header__UploadButton"
                        label="Upload"
                        IconLeft={Icons.Upload}
                        buttonType="Accent"
                        size="small"
                        onClick={() => setShowUpload(true)}
                    />
                </div>
            )}

            <DocumentsTable
                data={query.data?.data}
                isLoading={query.isLoading}
                isError={query.isError}
                onRowClick={(doc: DocumentFile) => setDetailDocId(doc.id)}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
            />

            {showUpload && categoriesQuery && (
                <DocumentUploadModal
                    directUpload={true}
                    afterUpload={attachUploadedDocument}
                    mobileTitle={entityTitle}
                    close={() => setShowUpload(false)}
                />
            )}

            {detailModel && (
                <DocumentDetailModal
                    model={detailModel}
                    mobileTitle={entityTitle}
                    isReadOnly={isReadOnly}
                    onArchive={handleArchive}
                    close={() => setDetailDocId(undefined)}
                />
            )}
        </div>
    );
}
