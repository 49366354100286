import { FormControl, InputLabel, OutlinedInput, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    CriteriaCompProps,
    DataTableCriteriaFieldBase,
    DataTableCriteriaPlugin,
} from '../DataTableCriteriaTypes';
import './ToggleCriteriaField.scss';

export type ToggleCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'toggle';
    trueValue?: string;
    falseValue?: string;
};

function ToggleCriteriaField({ field, onChange }: CriteriaCompProps<ToggleCriteriaFieldConfig>) {
    const trueValue = field.config.trueValue ?? 'true';
    const falseValue = field.config.falseValue ?? 'false';
    const [value, setValue] = useState(field.config.defaultValue ?? falseValue);

    const handleCheckedChanged = (checked: boolean) => {
        const val = checked ? trueValue : falseValue;
        setValue(val);
        field.value = val;
        onChange?.(field);
    };

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <FormControl
            className="DataTableCriteria__Field ToggleCriteriaField"
            size="small"
            variant="outlined"
        >
            <InputLabel
                variant="outlined"
                shrink={true}
            >
                {field.config.label}
            </InputLabel>

            <OutlinedInput
                notched={true}
                label={field.config.label}
                className="ToggleCriteriaField__OutlinedInput"
                type="hidden"
                // slots={{
                //     input: Switch,
                // }}
                // slotProps={{
                //     input: {
                //         className: 'ToggleCriteriaField__Switch',
                //         checked: value === trueValue,
                //         onChange: e => handleCheckedChanged(e.target.checked),
                //     },
                // }}
            />
            <div className="ToggleCriteriaField__SwitchWrapper">
                <Switch
                    className="ToggleCriteriaField__Switch"
                    checked={value === trueValue}
                    onChange={e => handleCheckedChanged(e.target.checked)}
                />
            </div>
        </FormControl>
    );
}

const plugin: DataTableCriteriaPlugin<ToggleCriteriaFieldConfig> = {
    Component: ToggleCriteriaField,
};
export default plugin;
