import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import SalesOrdersTable from 'features/sales/components/SalesOrdersTable/SalesOrdersTable';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function SalesOrdersPage() {
    usePageTitle('Sales');
    return (
        <OrderPresentation isSales={true}>
            <PageHeader title="Sales">
                <MyButton
                    label="New sales order"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="new"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>
            <SalesOrdersTable />
            <Outlet />
        </OrderPresentation>
    );
}
