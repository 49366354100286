import { mergeDeep } from 'utils/helpers';

export type PurchaseOrderEditable = {
    id: number;
    company_id: number;
    employee_id: number | null;
    sidemark: string | null;
    details: string | null;
    sales_rep_override: string | null;
};

export class PurchaseOrderEditableFactory {
    static create(
        data?: Partial<PurchaseOrderEditable> & { company_id: number; employee_id: number | null },
    ): PurchaseOrderEditable {
        const defaults: PurchaseOrderEditable = {
            id: 0,
            company_id: 0,
            employee_id: 0,
            sidemark: '',
            details: '',
            sales_rep_override: '',
        };

        const mergedData = mergeDeep(defaults, data);

        return mergedData;
    }
}
