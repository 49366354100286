import { mergeDeep } from 'utils/helpers';

export type SalesOrderEditable = {
    id: number;
    company_id: number;
    employee_id: number | null;
    sidemark: string | null;
    details: string | null;
    sales_rep_override: string | null;
    customer_details_override?: string | null;
};

export class SalesOrderEditableFactory {
    static create(
        data?: Partial<SalesOrderEditable> & { company_id: number; employee_id: number | null },
    ): SalesOrderEditable {
        const defaults: SalesOrderEditable = {
            id: 0,
            company_id: 0,
            employee_id: 0,
            sidemark: '',
            details: '',
            sales_rep_override: '',
            customer_details_override: '',
        };

        const mergedData = mergeDeep(defaults, data);

        return mergedData;
    }
}
