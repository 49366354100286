import SalesOrderCreateModal from 'features/sales/components/SalesOrderCreateModal/SalesOrderCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function SalesOrderCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();

    return <SalesOrderCreateModal close={() => navigate(`/sales-orders`)} />;
}
