import DndContainer from 'components/ReactSmoothDnd/DndContainer';
import Draggable from 'components/ReactSmoothDnd/Draggable';
import React, { useMemo } from 'react';
import { ContainerOptions, DropResult } from 'smooth-dnd';
import coalesceClassNames from 'utils/coalesceClassNames';
import './Kanban.scss';

export type KanbanColumnDefinition<T> = {
    id: string;
    title: string;
    /** Cards can be dragged between columns with the same group */
    group?: string;
    /** A filter function used when selecting the items that appear in this column
     * If a card matches multiple columns it will be added to the first one
     */
    cardSelector: (item: T) => boolean;
};

export default function Kanban<T>({
    className,
    data,
    columns,
    renderCard,
    onCardDrop,
}: {
    className?: string;
    data?: T[];
    columns: KanbanColumnDefinition<T>[];
    renderCard?: (item: T) => React.ReactFragment;
    onCardDrop?: (item: T, destColumn: KanbanColumnDefinition<T>, after: T | undefined) => void;
}) {
    /** A hash of cards in each column as dictated by the column selector */
    const dataByColumn = useMemo(() => {
        // create a hash of column ids to arrays of items
        const hash = columns.reduce((res, col) => {
            res[col.id] = [];
            return res;
        }, {} as Record<string, T[]>);

        // loop through data and add item to first column that matches
        data?.forEach(item => {
            const col = columns.find(c => c.cardSelector(item));
            if (col) {
                hash[col.id].push(item);
            }
        });
        return hash;
    }, [columns, data]);

    return (
        <div className={coalesceClassNames('Kanban', className)}>
            {columns.map(col => (
                <Column
                    key={col.id}
                    column={col}
                    data={dataByColumn[col.id]}
                    renderCard={renderCard}
                    onCardDrop={onCardDrop}
                />
            ))}
        </div>
    );
}

function Column<T>({
    column,
    data,
    renderCard,
    onCardDrop,
}: {
    column: KanbanColumnDefinition<T>;
    data: T[];
    renderCard?: (item: T) => React.ReactFragment;
    onCardDrop?: (item: T, destColumn: KanbanColumnDefinition<T>, after: T | undefined) => void;
}) {
    const [isDroppable, setIsDroppable] = React.useState(false);

    /** Check if an item can be dropped where you have dragged it */
    const handleShouldAcceptDrop = (source: ContainerOptions /* , payload: T */) => {
        return source.groupName === column.group;
    };

    const handleDragEnter = () => {
        setIsDroppable(true);
    };

    const handleDragLeave = () => {
        setIsDroppable(false);
    };

    const handleDrop = (result: DropResult) => {
        setIsDroppable(false);
        if (result.addedIndex !== null) {
            const _data = [...data];
            if (result.removedIndex !== null) {
                _data.splice(result.removedIndex, 1);
            }
            const cardAbove = result.addedIndex > 0 ? _data[result.addedIndex - 1] : undefined;
            onCardDrop?.(result.payload, column, cardAbove);
        }
    };

    return (
        <div
            className={coalesceClassNames(
                'Kanban__Column',
                `Kanban__Column--${column.id}`,
                isDroppable && 'Kanban__Column--Droppable',
            )}
        >
            <div className="Kanban__Column__Header">
                <h3 className="Kanban__Column__Header__Title">{column.title}</h3>
                <div className="Kanban__Column__Header__Count">{data.length}</div>
            </div>
            <DndContainer
                groupName={column.group}
                getChildPayload={i => data[i]}
                onDrop={handleDrop}
                shouldAcceptDrop={handleShouldAcceptDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                dragClass="DRAGGING"
                dropClass="DROPPING"
            >
                {data.length === 0 && <div className="Kanban__Column__Empty"></div>}
                {data.map((item, i) => (
                    <Draggable key={i}>
                        <div className="Kanban__Card">{renderCard?.(item)}</div>
                    </Draggable>
                ))}
            </DndContainer>
        </div>
    );
}
