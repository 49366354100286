import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import { SalesOrderEditable } from 'features/sales/models/SalesOrderEditable';
import React from 'react';
import './SalesOrderEditFields.scss';

export default function SalesOrderEditFields({
    editModel,
    isSaving,
    updateField,
}: {
    editModel: SalesOrderEditable;
    isSaving: boolean;
    updateField: (data: Partial<SalesOrderEditable>) => void;
}) {
    const salesRepOptions = useSalesRepOptions();

    return (
        <div className="SalesOrderEditFields">
            <PropertyContainer>
                <PropertyEditAutocomplete
                    label="Sales rep"
                    value={editModel.sales_rep_override ?? ''}
                    options={salesRepOptions}
                    onChange={sales_rep_override => updateField({ sales_rep_override })}
                    disabled={isSaving}
                    freeSolo={true}
                />

                <PropertyEditText
                    label="Sidemark"
                    value={editModel.sidemark ?? ''}
                    onChange={sidemark => updateField({ sidemark })}
                    disabled={isSaving}
                />

                <PropertyEditText
                    label="Customer details"
                    value={editModel.customer_details_override ?? ''}
                    onChange={customer_details_override =>
                        updateField({ customer_details_override })
                    }
                    disabled={isSaving}
                    multiline={true}
                />

                <PropertyEditText
                    label="Notes"
                    value={editModel.details ?? ''}
                    onChange={details => updateField({ details })}
                    multiline={true}
                    disabled={isSaving}
                />
            </PropertyContainer>
        </div>
    );
}
