import { SalesOrderEditable } from './SalesOrderEditable';

export type SalesOrderCreate = SalesOrderEditable & {
    is_draft: boolean;
};

export class SalesOrderCreateFactory {
    static create(
        data?: Partial<SalesOrderCreate> & {
            company_id: number;
            employee_id: number | null;
        },
    ): SalesOrderCreate {
        const defaults: SalesOrderCreate = {
            id: 0,
            company_id: 0,
            employee_id: 0,
            sidemark: '',
            details: '',
            customer_details_override: '',
            sales_rep_override: '',
            is_draft: false,
        };

        const mergedData = { ...defaults, ...data };

        return mergedData;
    }
}
