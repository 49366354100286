import { selectDealerModules } from 'features/settings/settings.slice';
import React, { createContext, useContext, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';

type OrderPresentationData = {
    isSales: boolean;
    routeBase: string;
    topLevelPageTitle: string;
    entityTitle: string;
};

export const OrderPresentationContext = createContext<OrderPresentationData | undefined>(undefined);
export const useOrderPresentation = () => {
    const ctx = useContext(OrderPresentationContext);
    if (!ctx) {
        throw new Error(
            'OrderPresentationContext not found in the tree. Make sure to wrap your component with OrderPresentation.',
        );
    }
    return ctx;
};

/** This provider allows us to make components common to both Sales and Purchases without passing in loads of context parameters */
export default function OrderPresentation({
    isSales,
    children,
}: {
    isSales: boolean;
    children?: React.ReactFragment;
}) {
    const dealerModules = useAppSelector(selectDealerModules);

    const ctx: OrderPresentationData = useMemo(
        () => ({
            isSales,
            routeBase: isSales ? '/sales-orders' : '/purchase-orders',
            topLevelPageTitle: isSales ? 'Sales' : dealerModules?.sales ? 'Purchases' : 'Orders',
            entityTitle: isSales
                ? 'Sales Order'
                : dealerModules?.sales
                ? 'Purchase Order'
                : 'Order',
        }),
        [dealerModules?.sales, isSales],
    );
    return (
        <OrderPresentationContext.Provider value={ctx}>
            {children}
        </OrderPresentationContext.Provider>
    );
}
