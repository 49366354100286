import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { selectLastUsedSalesRep, setLastUsedSalesRep } from 'features/orders/orders.slice';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import {
    PurchaseOrderEditable,
    PurchaseOrderEditableFactory,
} from 'features/purchases/models/PurchaseOrderEditable';
import purchasesApi from 'features/purchases/purchases.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PurchaseOrderEditFields from '../PurchaseOrderEditFields/PurchaseOrderEditFields';
import './PurchaseOrderCreateModal.scss';

export default function PurchaseOrderCreateModal({ close }: { close?: () => void }) {
    const company = useAppSelector(selectCurrentCompany);
    const employee_id = useAppSelector(selectCurrentUser)?.id;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const defaultSalesRep = useAppSelector(selectLastUsedSalesRep);

    const { topLevelPageTitle } = useOrderPresentation();

    const model = useMemo(
        () =>
            company && employee_id
                ? PurchaseOrderEditableFactory.create({
                      //   is_draft: true,
                      company_id: company.id,
                      employee_id,
                      sales_rep_override: defaultSalesRep,
                  })
                : undefined,
        [company, defaultSalesRep, employee_id],
    );

    const [saveMutation, saveMutationState] = purchasesApi.usePurchaseOrderCreateMutation();

    const save = async (editModel: PurchaseOrderEditable) => {
        dispatch(setLastUsedSalesRep(editModel.sales_rep_override ?? ''));
        const id = await saveMutation(editModel).unwrap();
        navigate(`/purchase-orders/${id}`);
    };

    return (
        <MyEditModal
            className="PurchaseOrderCreateModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New purchase order"
            mobileTitle={topLevelPageTitle}
            saveButtonLabel="Create"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <MessagePanel
                        className="PurchaseOrderCreateModal__MessagePanel"
                        messageType="info"
                    >
                        <p>
                            You can edit these details later, all fields are optional when creating
                            a new order.
                        </p>
                    </MessagePanel>
                    <PurchaseOrderEditFields
                        editModel={editModel}
                        isSaving={isSaving}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
