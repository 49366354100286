import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import {
    SalesOrderEditable,
    SalesOrderEditableFactory,
} from 'features/sales/models/SalesOrderEditable';
import salesApi from 'features/sales/sales.api';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import SalesOrderEditFields from '../SalesOrderEditFields/SalesOrderEditFields';
import './SalesOrderEditModal.scss';

export default function SalesOrderEditModal({
    order,
    close,
}: {
    order: SalesOrderDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = salesApi.useSalesOrderUpdateMutation();

    const employee_id = useAppSelector(selectCurrentUser)?.id;

    const model = useMemo(
        () =>
            SalesOrderEditableFactory.create({
                id: order.id,
                company_id: order.company_id,
                employee_id: order.employee_id ?? employee_id ?? null,
                sidemark: order.sidemark,
                sales_rep_override: order.sales_rep_override,
                details: order.details,
                customer_details_override: order.customer_details_override,
            }),
        [employee_id, order],
    );

    const save = async (editModel: SalesOrderEditable) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="SalesOrderEditModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={order.unique_id}
            mobileTitle="Sales Order"
        >
            {({ editModel, updateField, isSaving }) => (
                <SalesOrderEditFields
                    editModel={editModel}
                    isSaving={isSaving}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
