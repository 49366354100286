import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { SalesOrderCreate, SalesOrderCreateFactory } from 'features/sales/models/SalesOrderCreate';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import salesApi from 'features/sales/sales.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import SalesOrderEditFields from '../SalesOrderEditFields/SalesOrderEditFields';
import './SalesOrderCloneModal.scss';

export default function SalesOrderCloneModal({
    order,
    windowIds,
    close,
}: {
    order: SalesOrderDetail;
    windowIds: number[];
    close?: () => void;
}) {
    const company_id = useAppSelector(selectCurrentCompany)?.id;
    const employee_id = useAppSelector(selectCurrentUser)?.id;

    const model = useMemo(
        () =>
            company_id && employee_id
                ? SalesOrderCreateFactory.create({
                      is_draft: false,
                      company_id,
                      employee_id,
                      details: order.details,
                      sidemark: order.sidemark,
                      sales_rep_override: order.sales_rep_override,
                      customer_details_override: order.customer_details_override,
                  })
                : undefined,
        [
            company_id,
            employee_id,
            order.customer_details_override,
            order.details,
            order.sales_rep_override,
            order.sidemark,
        ],
    );

    const [cloneMutation, cloneMutationState] = salesApi.useSalesOrderCloneMutation();

    const navigate = useNavigate();
    const save = async (editModel: SalesOrderCreate) => {
        const id = await cloneMutation({
            orderId: order.id,
            details: editModel.details ?? '',
            sidemark: editModel.sidemark ?? '',
            sales_rep_override: editModel.sales_rep_override ?? '',
            customer_details_override: editModel.customer_details_override ?? '',
            is_draft: editModel.is_draft,
            windowIds,
        }).unwrap();
        navigate(`/sales-orders/${id}`);
        close?.();
    };

    return (
        <MyEditModal
            className="SalesOrderCloneModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={cloneMutationState.isLoading}
            title="Clone Sales Order"
            subtitle="Copy all products to a new order."
            mobileTitle="Sales Order"
            saveButtonLabel="Create clone"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <MessagePanel
                        className="SalesOrderCloneModal__MessagePanel"
                        messageType="info"
                    >
                        <p>All fields are optional when cloning an order.</p>
                    </MessagePanel>

                    <SalesOrderEditFields
                        editModel={editModel}
                        isSaving={isSaving}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
