import z from 'zod';
import { ManufacturerOrderSchema } from './ManufacturerOrder';

export const PurchaseOrderOrderingFormSchema = z.object({
    id: z.number(),
    order_id: z.number(),
    brand_id: z.number().nullable(),
    category_id: z.number().nullable(),
    purchase_order_id: z.string(),
    manufacturer_orders: z.array(ManufacturerOrderSchema),
    supplier_order_number: z.string().nullable(),
});

export const PurchaseOrderOrderingInfoSchema = z.object({
    id: z.number(),

    products: z.array(
        z.object({
            source_window_product_id: z.number(),
            id: z.number(),
        }),
    ),
    trackings: z.array(
        z.object({
            order_window_product_id: z.number(),
            order_id: z.number(),
            brand_id: z.number(),
            category_id: z.number(),
        }),
    ),
    forms: z.array(PurchaseOrderOrderingFormSchema),
});

export type PurchaseOrderOrderingForm = z.infer<typeof PurchaseOrderOrderingFormSchema>;
export type PurchaseOrderOrderingInfo = z.infer<typeof PurchaseOrderOrderingInfoSchema>;
