import ordersApi from 'features/orders/orders.api';
import PurchaseOrderDetailModal from 'features/purchases/components/PurchaseOrderDetailModal/PurchaseOrderDetailModal';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useCallback, useEffect } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseDetailPage() {
    const { orderId } = useParams() as { orderId: string };
    return (
        // Use a separate comp so we can key it based on the orderId
        // this prevents cross-contamination of data when switching between orders
        <PurchaseDetailPagePure
            key={orderId}
            orderId={parseInt(orderId, 10)}
        />
    );
}

function PurchaseDetailPagePure({ orderId }: { orderId: number }) {
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const orderQuery = purchasesApi.usePurchaseOrderDetailQuery(orderId);
    usePageTitle(orderQuery.data?.tuid);
    const windowsQuery = ordersApi.useOrderWindowListQuery(orderId);

    const handleClose = useCallback(() => {
        // Clear tab when closing modal
        urlParams.delete('tab');
        navigate(`/purchase-orders?${urlParams}`);
    }, [navigate, urlParams]);

    // Handle redirecting based on order id and state
    useEffect(() => {
        const model = orderQuery.currentData;

        if (model) {
            // always redirect to latest revision of submitted drafts
            if (model.context.latestRevision?.id && model.context.latestRevision.id !== model.id) {
                navigate(`/purchase-orders/${model.context.latestRevision.id}?${urlParams}`);
            }
        }
    }, [
        navigate,
        orderQuery.currentData,
        orderQuery.currentData?.context.latestRevision?.id,
        urlParams,
    ]);

    return orderId ? (
        <>
            <PurchaseOrderDetailModal
                model={orderQuery.data}
                windows={windowsQuery.data}
                isLoading={orderQuery.isLoading || windowsQuery.isLoading}
                isError={orderQuery.isError || windowsQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </>
    ) : null;
}
