import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import { selectCurrentUser } from 'features/auth/auth.slice';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import { selectLastUsedSalesRep, setLastUsedSalesRep } from 'features/orders/orders.slice';
import { SalesOrderCreate, SalesOrderCreateFactory } from 'features/sales/models/SalesOrderCreate';
import salesApi from 'features/sales/sales.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './SalesOrderCreateModal.scss';

export default function SalesOrderCreateModal({ close }: { close?: () => void }) {
    const company = useAppSelector(selectCurrentCompany);
    const employee_id = useAppSelector(selectCurrentUser)?.id;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const defaultSalesRep = useAppSelector(selectLastUsedSalesRep);
    const salesRepOptions = useSalesRepOptions();

    const model = useMemo(
        () =>
            company && employee_id
                ? SalesOrderCreateFactory.create({
                      is_draft: false,
                      company_id: company.id,
                      employee_id,
                      sales_rep_override: defaultSalesRep,
                  })
                : undefined,
        [company, defaultSalesRep, employee_id],
    );

    const [saveMutation, saveMutationState] = salesApi.useSalesOrderCreateMutation();

    const save = async (editModel: SalesOrderCreate) => {
        dispatch(setLastUsedSalesRep(editModel.sales_rep_override ?? ''));
        const id = await saveMutation(editModel).unwrap();
        navigate(`/sales-orders/${id}`);
    };

    return (
        <MyEditModal
            className="SalesOrderCreateModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New sales order"
            mobileTitle="Sales"
            saveButtonLabel="Create"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <PropertyContainer>
                        <PropertyEditAutocomplete
                            label="Sales rep"
                            hint="Select an existing name or type a new one"
                            options={salesRepOptions}
                            value={editModel.sales_rep_override ?? ''}
                            onChange={sales_rep_override => updateField({ sales_rep_override })}
                            disabled={isSaving}
                            freeSolo={true}
                            autoSelect={true}
                        />
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
