/* eslint-disable global-require, @typescript-eslint/no-var-requires */
import Env from 'config/Env';

export const initMockServiceWorker = () => {
    if (Env.MSW_ENABLED) {
        const { worker } = require('./browser');
        worker.start({
            onUnhandledRequest: 'bypass',
            serviceWorker: {
                url: '/mockServiceWorker.js',
            },
        });

        // worker.printHandlers();
    }
};
