import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import {
    PurchaseOrderEditable,
    PurchaseOrderEditableFactory,
} from 'features/purchases/models/PurchaseOrderEditable';
import purchasesApi from 'features/purchases/purchases.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import PurchaseOrderEditFields from '../PurchaseOrderEditFields/PurchaseOrderEditFields';
import './PurchaseOrderCloneModal.scss';

export default function PurchaseOrderCloneModal({
    order,
    windowIds,
    close,
}: {
    order: PurchaseOrderDetail;
    windowIds: number[];
    close?: () => void;
}) {
    const company_id = useAppSelector(selectCurrentCompany)?.id;
    const employee_id = useAppSelector(selectCurrentUser)?.id;

    const model = useMemo(
        () =>
            company_id && employee_id
                ? PurchaseOrderEditableFactory.create({
                      company_id,
                      employee_id,
                      details: order.notes,
                      sidemark: order.sidemark,
                      sales_rep_override: order.salesRep,
                  })
                : undefined,
        [company_id, employee_id, order.notes, order.salesRep, order.sidemark],
    );

    const [cloneMutation, cloneMutationState] = purchasesApi.usePurchaseOrderCloneMutation();

    const navigate = useNavigate();
    const save = async (editModel: PurchaseOrderEditable) => {
        const id = await cloneMutation({
            orderId: order.id,
            details: editModel.details ?? '',
            sidemark: editModel.sidemark ?? '',
            sales_rep_override: editModel.sales_rep_override ?? '',
            windowIds,
        }).unwrap();
        navigate(`/purchase-orders/${id}`);
        close?.();
    };

    return (
        <MyEditModal
            className="PurchaseOrderCloneModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={cloneMutationState.isLoading}
            title="Clone Purchase Order"
            subtitle="Copy all products to a new order."
            mobileTitle="Purchase Order"
            saveButtonLabel="Create clone"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <MessagePanel
                        className="PurchaseOrderCloneModal__MessagePanel"
                        messageType="info"
                    >
                        <p>All fields are optional when cloning an order.</p>
                    </MessagePanel>
                    <PurchaseOrderEditFields
                        editModel={editModel}
                        isSaving={isSaving}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
