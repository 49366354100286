import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import {
    CriteriaCompProps,
    DataTableCriteriaFieldBase,
    DataTableCriteriaPlugin,
} from '../DataTableCriteriaTypes';

export type TextCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'text';
    exact?: boolean;
};

function TextCriteriaField({ field, onChange }: CriteriaCompProps<TextCriteriaFieldConfig>) {
    const [value, setValue] = useState(field.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        field.value = val;
        onChange?.(field);
    };

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <div className={coalesceClassNames('DataTableCriteria__Field', 'TextCriteriaField')}>
            <MyTextInput
                label={field.config.label}
                value={value}
                handleInput={handleInput}
                showClearButton={true}
            />
        </div>
    );
}

const plugin: DataTableCriteriaPlugin<TextCriteriaFieldConfig> = {
    Component: TextCriteriaField,
};
export default plugin;
