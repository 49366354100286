import z from 'zod';

export const ManufacturerOrderStatusSchema = z.object({
    id: z.number(),
    manufacturerId: z.number(),
    customerStatus: z.string(),
    backgroundColor: z.string(),
    coreStatus: z.string(),
    textColor: z.string(),
    isActive: z.boolean(),
    sortOrder: z.number(),
});

export type ManufacturerOrderStatus = z.infer<typeof ManufacturerOrderStatusSchema>;
