import { authHandlers } from 'features/auth/auth.mocks';
import { dashboardHandlers } from 'features/dashboard/dashboard.mocks';
import { ordersHandlers } from 'features/orders/orders.mocks';
import { purchasesHandlers } from 'features/purchases/purchases.mocks';
import { salesHandlers } from 'features/sales/sales.mocks';
import { settingsHandlers } from 'features/settings/settings.mocks';

export const handlers = [
    ...authHandlers,
    ...dashboardHandlers,
    ...ordersHandlers,
    ...purchasesHandlers,
    ...salesHandlers,
    ...settingsHandlers,
];
