import Icons from 'Icons';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { getDeepValue, isEmpty } from 'utils/helpers';
import {
    DataTableFilterBase,
    DataTableFilterPlugin,
    FilterCompProps,
} from '../DataTableFilterTypes';

export type SearchFilterConfig = DataTableFilterBase & {
    type: 'search';
    fields: string[];
};

function SearchFilter({ filter, onChange }: FilterCompProps<SearchFilterConfig>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--SearchFilter',
            )}
        >
            <MyTextInput
                // label={filter.label}
                value={value}
                handleInput={handleInput}
                LeftIcon={Icons.Search}
                showClearButton={true}
            />
        </div>
    );
}

const plugin: DataTableFilterPlugin<SearchFilterConfig> = {
    Component: SearchFilter,
    applyFilter: (filter, value, item) => {
        // make sure each token is found on one of the fields
        const tokens = value.trim().split(' ');

        // every token must match at least one field
        return tokens.every(token =>
            filter.fields.some(field => {
                let val = getDeepValue(item, field);
                if (isEmpty(val)) {
                    val = '';
                }
                return String(val).toLowerCase().indexOf(token.toLowerCase()) > -1;
            }),
        );
    },
};
export default plugin;
