import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import { PurchaseOrderEditable } from 'features/purchases/models/PurchaseOrderEditable';
import React from 'react';
import './PurchaseOrderEditFields.scss';

export default function PurchaseOrderEditFields({
    editModel,
    isSaving,
    updateField,
}: {
    editModel: PurchaseOrderEditable;
    isSaving: boolean;
    updateField: (data: Partial<PurchaseOrderEditable>) => void;
}) {
    const salesRepOptions = useSalesRepOptions();

    return (
        <div className="PurchaseOrderEditFields">
            <PropertyContainer>
                <PropertyEditAutocomplete
                    label="Sales rep"
                    value={editModel.sales_rep_override ?? ''}
                    options={salesRepOptions}
                    onChange={sales_rep_override => updateField({ sales_rep_override })}
                    disabled={isSaving}
                    freeSolo={true}
                />

                <PropertyEditText
                    label="Sidemark"
                    value={editModel.sidemark ?? ''}
                    onChange={sidemark => updateField({ sidemark })}
                    disabled={isSaving}
                />

                <PropertyEditText
                    label="Notes"
                    value={editModel.details ?? ''}
                    onChange={details => updateField({ details })}
                    multiline={true}
                    disabled={isSaving}
                />
            </PropertyContainer>
        </div>
    );
}
